import { Avatar } from 'antd';
import Image from 'next/image';
import { Dispatch, FC, SetStateAction, useState } from 'react';
import { useGetSignedUserDataQuery } from '../../../graphql/types';
import RemoveCrewMember from './RemoveCrewMember';

interface CrewMemberProps {
  setHideList?: Dispatch<
    SetStateAction<{
      value: boolean;
      data: any;
    }>
  >;
  hideList?: {
    value: boolean;
    data: any;
  };
  reload: () => void;
}

const CrewMember: FC<CrewMemberProps> = ({ setHideList, hideList, reload }) => {
  const { data } = useGetSignedUserDataQuery();
  const name = hideList?.data.user.displayName;
  const role = hideList?.data.role && hideList?.data.role[0];
  const profilePic = hideList?.data.profilePicURL;
  const email = hideList?.data.user.email;
  const phone = hideList?.data.user.phone;
  const [editMember, setEditMember] = useState<boolean>(false);
  const [editType, setEditType] = useState<'remove' | 'deactivate'>('remove');

  return (
    <div className="crew-member-container">
      <div className="header row">
        <div
          className="row pointer"
          onClick={() =>
            setHideList &&
            setHideList({
              value: false,
              data: {},
            })
          }>
          <Image src={'/icons/left_arrow_purple.svg'} alt="back crew member" width={24} height={24} layout="fixed" />
          <h1
            style={{
              fontWeight: '700',
              fontSize: '12px',
              lineHeight: '14px',
              letterSpacing: '0.6px',
            }}>
            Back
          </h1>
        </div>

        {hideList?.data.user.id !== data?.signedInUser?.id && (
          <div className="row">
            <div
              className="row pointer"
              onClick={() => {
                setEditMember(true);
                setEditType('deactivate');
              }}>
              <Image src={'/icons/edit_square_purple.svg'} alt="deactivate crew member" width={24} height={24} layout="fixed" />
            </div>

            <div
              className="row pointer"
              style={{ marginLeft: 10 }}
              onClick={() => {
                setEditMember(true);
                setEditType('remove');
              }}>
              <Image src={'/icons/bin_purple.svg'} alt="remove crew member" width={24} height={24} layout="fixed" />
            </div>
          </div>
        )}
      </div>

      <div className="row border">
        <Avatar shape="square" size={48} style={{ borderRadius: 8, marginRight: 22 }} src={profilePic}>
          {!profilePic && name.charAt(0)}
        </Avatar>
        <div>
          <h1 className="big-text">{name}</h1>
          <h1 className="gray-text">{role}</h1>
        </div>
      </div>

      <div className="row border">
        <Image src={'/icons/mail_gray_2.svg'} alt="mail crew member" width={24} height={24} layout="fixed" />
        <div style={{ marginLeft: 22 }}>
          <h1 className="gray-text">Email</h1>
          <h1 className="second-big-text">{email}</h1>
        </div>
      </div>

      <div className="row border">
        <Image src={'/icons/phone_gray.svg'} alt="phone crew member" width={24} height={24} layout="fixed" />
        <div style={{ marginLeft: 22 }}>
          <h1 className="gray-text">Phone</h1>
          <h1 className="second-big-text">{phone}</h1>
        </div>
      </div>

      <RemoveCrewMember setShow={setEditMember} show={editMember} type={editType} crewId={hideList?.data.id} reload={reload} setHideList={setHideList} />
    </div>
  );
};

export default CrewMember;
