import Image from 'next/image';
import { FC, useState } from 'react';
import { useTranslation } from 'react-i18next';

const Sorter: FC<{
  handleFilter?: (input: string) => Promise<void>;
  show: boolean;
}> = ({ handleFilter, show }) => {
  const { t } = useTranslation('page');
  const [open, setOpen] = useState<boolean>(false);

  const onGuestFilters = (input: string) => {
    if (handleFilter) {
      handleFilter(input);
      setOpen(false);
    }
  };

  return show ? (
    <div className="cell" onClick={() => setOpen(!open)}>
      <h1 className="right">{t('guests.sort')}</h1>
      <Image src={'/icons/down_arrow.svg'} layout="fixed" width={16} height={16} alt="PM_guests_sorter" />

      <div className={`sorter ${open ? 'open' : ''}`}>
        <button className="sort" onClick={() => onGuestFilters('All Status')}>
          {t('guests.status.allStatus')}
        </button>
        <button className="sort" onClick={() => onGuestFilters('Attending')}>
          {t('guests.status.attending')}
        </button>
        <button className="sort" onClick={() => onGuestFilters('Approved')}>
          {t('guests.status.approved')}
        </button>
        <button className="sort" onClick={() => onGuestFilters('Requested')}>
          {t('guests.status.requested')}
        </button>
        <button className="sort" onClick={() => onGuestFilters('Declined')}>
          {t('guests.status.declined')}
        </button>
        <button className="sort" onClick={() => onGuestFilters('Invited')}>
          {t('guests.status.invited')}
        </button>
      </div>
    </div>
  ) : (
    <></>
  );
};

export default Sorter;
