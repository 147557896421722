import LoadingOutlined from '@ant-design/icons/LoadingOutlined';

import Progress from 'antd/lib/progress';
import Spin from 'antd/lib/spin';
import Image from 'next/image';
import Link from 'next/link';
import { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { Event, GuestList, PrivateUser, Page } from '../../../../graphql/types';
import { mainPages } from '../../../customTypes';
import { checkPage } from '../../../utils';

const loadingSpin = <LoadingOutlined style={{ fontSize: 12, color: '#fff' }} spin />;

interface LinkWrapProps {
  children: React.ReactNode;
  hasLink: boolean;
  href: string;
  as: string;
}

const LinkWrap: FC<LinkWrapProps> = ({ children, href, as, hasLink }) => {
  return (
    <>
      {hasLink ? (
        <Link href={href} as={as} passHref>
          {children}
        </Link>
      ) : (
        children
      )}
    </>
  );
};

interface Part3Props {
  page: mainPages;
  venue: Page | undefined;
  event: Event | undefined;
  account: PrivateUser | undefined;
  guestList: GuestList | undefined;
  numFormatter: (num: any) => any;
  crew?: any;
  href: string;
  as: string;
  smallScreen: boolean;
}

const Part3: FC<Part3Props> = ({ page, venue, event, guestList, crew, account, numFormatter, as, href, smallScreen }) => {
  const { t } = useTranslation('page');
  const eventAttending = checkPage(page).isEvents && event?.sourceAttendingCount;
  const eventInterested = checkPage(page).isEvents && event?.sourceInterestedCount;
  const venueCapacity = !checkPage(page).isEvents && venue && venue.capacityUsage ? Math.floor(venue.capacityUsage * 100) : 0;
  const guestListCount = checkPage(page).isGuestList && guestList?.totalGuests ? guestList?.totalGuests : 0;
  const crewPremissions = checkPage(page).isCrew && crew && Array.isArray(crew.permissions) && crew.permissions;

  return (
    <LinkWrap hasLink={!smallScreen} href={href} as={as}>
      <div className="part3">
        <div className="data">
          {checkPage(page).isEvents ? (
            <>
              {eventAttending || eventInterested ? (
                <>
                  <Image
                    src={'/icons/users.svg'}
                    style={{
                      marginRight: 2,
                    }}
                    width={16}
                    height={16}
                    alt="PM_event_attenting"
                  />
                  <h3>
                    {numFormatter(eventAttending ? eventAttending : 0)} {t('components:inputs.attending')} •{' '}
                    {numFormatter(eventInterested ? eventInterested : 0)} {t('components:inputs.interested')}
                  </h3>
                </>
              ) : (
                <></>
              )}
            </>
          ) : checkPage(page).isGuestList ? (
            <>
              <div className="guestlist-count-pill">{guestListCount !== undefined ? guestListCount : <Spin size="small" indicator={loadingSpin} />}</div>
              <Image src={'/icons/users.svg'} width={35} height={35} alt="PM_event_attenting" />
            </>
          ) : checkPage(page).isGuests || checkPage(page).isAccounts ? (
            <> </>
          ) : checkPage(page).isCrew ? (
            <div className="crew-permissions">
              {Array.isArray(crewPremissions) &&
                crewPremissions.map((e, i) => (
                  <div key={i} className="crew-permissions-pill">
                    <h2>{e}</h2>
                  </div>
                ))}
              {/* <Image src={'/icons/close_purple.svg'} width={24} height={24} alt="PM_event_attenting" /> */}
            </div>
          ) : (
            checkPage(page).isVenues ||
            checkPage(page).isFestivals ||
            (checkPage(page).isPromoters && (
              <>
                <h3>{t(`venues.${venueCapacity <= 50 ? 'enough' : venueCapacity > 50 && venueCapacity < 100 ? 'almost' : 'full'}`)}</h3>
                <Progress
                  type="circle"
                  percent={venueCapacity}
                  width={30}
                  strokeColor={venueCapacity <= 50 ? '#03DAC5' : venueCapacity > 50 && venueCapacity < 100 ? '#FEC400' : '#FF427E'}
                  strokeWidth={10}
                  trailColor={'#E7E9ED'}
                  style={{
                    marginLeft: 12,
                  }}
                  status={venueCapacity == 100 ? 'exception' : 'normal'}
                />
              </>
            ))
          )}
        </div>
      </div>
    </LinkWrap>
  );
};

export default Part3;
