import MinusOutlined from '@ant-design/icons/MinusOutlined';
import PlusOutlined from '@ant-design/icons/PlusOutlined';
import Collapse from 'antd/lib/collapse';
import { useTranslation } from 'react-i18next';
import { FC, useState } from 'react';
import {
  GetBusinessUsersQueryHookResult,
  GetEventGuestsQueryHookResult,
  GetEventsCountQueryHookResult,
  GetEventsDataQueryHookResult,
  GetGuestListsQueryHookResult,
  GetSignedUserDataQueryHookResult,
  GetVenuesCountQueryHookResult,
  GetVenuesDataQueryHookResult,
  UserPageRole,
} from '../../../graphql/types';
import { mainPages } from '../../customTypes';
import { checkPage } from '../../utils';
import CrewMember from '../Crew/CrewMember';
import LoadingData from '../StateScreens/LoadingData';
import NoData from '../StateScreens/NoData';
import DataContainer from './DataContainer';
import Header from './Header';
import SearchBar from './SearchBar/Index';

interface MainProps {
  page: mainPages;
  noData: boolean;
  handleSearch: (input: string) => Promise<void>;
  handleFilter?: (input: string) => Promise<void>;
  reload: () => void;
  events?: GetEventsDataQueryHookResult;
  venues?: GetVenuesDataQueryHookResult;
  guests?: GetEventGuestsQueryHookResult;
  upcomingEventsWithNoGuestlists?: GetEventsDataQueryHookResult;
  accounts?: GetBusinessUsersQueryHookResult;
  crew?: any;
  guestLists?: GetGuestListsQueryHookResult;
  eventsCounter?: GetEventsCountQueryHookResult;
  venuesCounter?: GetVenuesCountQueryHookResult;
  collapses?: Array<{ name: string; key: string }>;
  signedInUser?: GetSignedUserDataQueryHookResult;
}

const Main: FC<MainProps> = ({
  page,
  noData,
  handleSearch,
  handleFilter,
  reload,
  events,
  venues,
  guests,
  accounts,
  crew,
  guestLists,
  eventsCounter,
  venuesCounter,
  collapses,
  signedInUser,
  upcomingEventsWithNoGuestlists,
}) => {
  const { t } = useTranslation('page');
  const [activeCollapse, setActiveCollapse] = useState<any>(checkPage(page).isCrew ? '0' : '1');
  const [hideList, setHideList] = useState<{
    value: boolean;
    data: any;
  }>({
    value: false,
    data: {},
  });

  const isLoading =
    venues?.loading || events?.loading || guestLists?.loading || accounts?.loading || guests?.loading || upcomingEventsWithNoGuestlists?.loading;

  const isEmpty =
    (venues && venues.data && venues.data.venues.length === 0) ||
    (events && events.data && events.data.events.length === 0) ||
    (guests && guests.data && guests.data.eventGuestList.count === 0) ||
    (accounts && accounts.data && accounts.data.businessUsers.length === 0) ||
    (guestLists && guestLists.data && guestLists.data.guestLists.length === 0);

  return (
    <div>
      <Header page={page} reload={reload} pageId={crew && crew.pageId} crewLicense={crew ? crew.license : null} signedInUser={signedInUser} />
      <SearchBar
        page={page}
        handleSearch={handleSearch}
        handleFilter={handleFilter}
        eventsCounter={eventsCounter}
        venuesCounter={venuesCounter}
        guestsCounter={guests?.data?.eventGuestList.count}
        guestLists={guestLists}
        crew={crew}
      />
      {isLoading ? (
        <LoadingData />
      ) : noData || isEmpty ? (
        <NoData page={page} />
      ) : hideList.value && checkPage(page).isCrew ? (
        <CrewMember setHideList={setHideList} hideList={hideList} reload={reload} />
      ) : collapses ? (
        <Collapse
          bordered={false}
          ghost
          style={{
            marginBottom: 120,
          }}
          accordion={activeCollapse !== '0'}
          expandIconPosition="end"
          expandIcon={({ isActive }) =>
            isActive ? <MinusOutlined style={{ color: '#8849D6' }} size={16} /> : <PlusOutlined style={{ color: '#8849D6' }} size={16} />
          }
          onChange={e => setActiveCollapse(e)}
          activeKey={checkPage(page).isCrew && activeCollapse === '0' ? collapses.map((e, i) => (i + 1).toString()) : activeCollapse}>
          {collapses.map((e, i) => {
            const numberOfUpcomingGl = guestLists?.data?.guestLists.filter(item =>
              item.endTime
                ? new Date(item.endTime) > new Date()
                : new Date(new Date(item.startTime).setHours(new Date(item.startTime).getHours() + 8)) > new Date(),
            ).length;
            const numberOfExpiredGL = guestLists?.data?.guestLists.filter(item =>
              item.endTime
                ? new Date(item.endTime) < new Date()
                : new Date(new Date(item.startTime).setHours(new Date(item.startTime).getHours() + 8)) < new Date(),
            ).length;
            const numberOfClosedGL = guestLists?.data?.guestLists.filter(item => item.closed).length;

            const numberOfAdmins = crew?.crew?.filter(item => item.role?.includes(UserPageRole.Admin)).length;
            const numberOfCheckInOnly = crew?.crew?.filter(item => item.role?.includes(UserPageRole.CheckInOnly)).length;
            const numberOfPromoters = crew?.crew?.filter(item => item.role?.includes(UserPageRole.Promoter)).length;
            const numberOfSecurity = crew?.crew?.filter(item => item.role?.includes(UserPageRole.Security)).length;
            const numberOfSocialMedia = crew?.crew?.filter(item => item.role?.includes(UserPageRole.SocialMedia)).length;
            const numberOfNoRoles = crew?.crew?.length - numberOfAdmins;

            const headerName =
              guestLists && e.name === 'upcoming'
                ? `${t('guestlist.upcoming')} (${numberOfUpcomingGl})`
                : guestLists && e.name === 'expired'
                ? `${t('guestlist.expired')} (${numberOfExpiredGL})`
                : guestLists && e.name === 'closed'
                ? `${t('guestlist.closed')} (${numberOfClosedGL})`
                : crew && e.name === 'Admin'
                ? `${e.name} (${numberOfAdmins})`
                : crew && e.name === 'CheckInOnly'
                ? `${e.name} (${numberOfCheckInOnly})`
                : crew && e.name === 'Promoter'
                ? `${e.name} (${numberOfPromoters})`
                : crew && e.name === 'Security'
                ? `${e.name} (${numberOfSecurity})`
                : crew && e.name === 'SocialMedia'
                ? `${e.name} (${numberOfSocialMedia})`
                : crew && e.name === 'No role'
                ? `${e.name} (${numberOfNoRoles})`
                : e.name;

            return (
              <Collapse.Panel
                header={headerName}
                key={e.key}
                className={`guestlist-collapse-header ${activeCollapse && activeCollapse === e.key ? 'active' : ''}`}>
                <DataContainer
                  page={page}
                  events={events}
                  venues={venues}
                  guests={guests}
                  guestLists={guestLists}
                  accounts={accounts}
                  crew={crew}
                  reload={reload}
                  collapsePanelName={e.name}
                  setHideList={setHideList}
                  hideList={hideList}
                  upcomingEventsWithNoGuestlists={upcomingEventsWithNoGuestlists}
                />
              </Collapse.Panel>
            );
          })}
        </Collapse>
      ) : (
        <DataContainer
          page={page}
          events={events}
          venues={venues}
          guests={guests}
          accounts={accounts}
          reload={reload}
          upcomingEventsWithNoGuestlists={upcomingEventsWithNoGuestlists}
        />
      )}
    </div>
  );
};

export default Main;
