import { Dispatch, FC, SetStateAction, useState } from 'react';
import {
  GetBusinessUsersQueryHookResult,
  GetEventGuestsQueryHookResult,
  GetEventsDataQueryHookResult,
  GetGuestListsQueryHookResult,
  GetVenuesDataQueryHookResult,
} from '../../../graphql/types';
import { mainPages } from '../../customTypes';
import { checkPage } from '../../utils';
import DataRow from './DataRow/Index';

interface DataContainerProps {
  page: mainPages;
  events?: GetEventsDataQueryHookResult;
  venues?: GetVenuesDataQueryHookResult;
  guests?: GetEventGuestsQueryHookResult;
  accounts?: GetBusinessUsersQueryHookResult;
  upcomingEventsWithNoGuestlists?: GetEventsDataQueryHookResult;
  crew?: any;
  guestLists?: GetGuestListsQueryHookResult;
  collapsePanelName?: string;
  setHideList?: Dispatch<
    SetStateAction<{
      value: boolean;
      data: any;
    }>
  >;
  hideList?: {
    value: boolean;
    data: any;
  };
  reload: () => void;
}

const DataContainer: FC<DataContainerProps> = ({
  page,
  events,
  venues,
  guests,
  accounts,
  crew,
  guestLists,
  collapsePanelName,
  reload,
  setHideList,
  hideList,
  upcomingEventsWithNoGuestlists,
}) => {
  const [selected, setSelected] = useState<string | undefined>('');

  const listData = checkPage(page).isEvents
    ? events?.data?.events
    : checkPage(page).isGuests
    ? guests?.data?.eventGuestList.guestList
    : checkPage(page).isVenues || checkPage(page).isFestivals || checkPage(page).isPromoters
    ? venues?.data?.venues
    : checkPage(page).isAccounts
    ? accounts?.data?.businessUsers
    : checkPage(page).isGuestList
    ? guestLists?.data?.guestLists
    : checkPage(page).isCrew
    ? crew.crew
    : [];

  const eventsWithoutGuestlist = checkPage(page).isGuestList ? upcomingEventsWithNoGuestlists?.data?.events : [];

  console.log(listData);

  return (
    <div className={`data-container ${checkPage(page).isAccounts ? 'h-75' : checkPage(page).isCrew || checkPage(page).isGuestList ? 'collapsed' : ''}`}>
      {checkPage(page).isGuestList && Array.isArray(listData)
        ? listData.map((data, index) => {
            console.log(data);
            return (
              <>
                {data.closed && collapsePanelName === 'closed' ? (
                  <DataRow
                    event={data}
                    venue={data}
                    guest={data}
                    account={data}
                    guestList={data}
                    crew={data}
                    selected={selected}
                    setSelected={setSelected}
                    page={page}
                    reload={reload}
                    setHideList={setHideList}
                    hideList={hideList}
                    top={index === listData.length - 1 ? '-120%' : '80%'}
                  />
                ) : data.endTime ? (
                  new Date(data.endTime) > new Date() && collapsePanelName === 'upcoming' ? (
                    <DataRow
                      event={data}
                      venue={data}
                      guest={data}
                      account={data}
                      guestList={data}
                      crew={data}
                      selected={selected}
                      setSelected={setSelected}
                      page={page}
                      reload={reload}
                      setHideList={setHideList}
                      hideList={hideList}
                      top={index === listData.length - 1 ? '-120%' : '80%'}
                    />
                  ) : new Date(data.endTime) < new Date() && collapsePanelName === 'expired' ? (
                    <DataRow
                      event={data}
                      venue={data}
                      guest={data}
                      account={data}
                      guestList={data}
                      crew={data}
                      selected={selected}
                      setSelected={setSelected}
                      page={page}
                      reload={reload}
                      setHideList={setHideList}
                      hideList={hideList}
                      top={index === listData.length - 1 ? '-120%' : '80%'}
                    />
                  ) : (
                    <></>
                  )
                ) : new Date(new Date(data.startTime).setHours(new Date(data.startTime).getHours() + 8)) > new Date() && collapsePanelName === 'upcoming' ? (
                  <DataRow
                    event={data}
                    venue={data}
                    guest={data}
                    account={data}
                    guestList={data}
                    crew={data}
                    selected={selected}
                    setSelected={setSelected}
                    page={page}
                    reload={reload}
                    setHideList={setHideList}
                    hideList={hideList}
                    top={index === listData.length - 1 ? '-120%' : '80%'}
                  />
                ) : new Date(new Date(data.startTime).setHours(new Date(data.startTime).getHours() + 8)) < new Date() && collapsePanelName === 'expired' ? (
                  <DataRow
                    event={data}
                    venue={data}
                    guest={data}
                    account={data}
                    guestList={data}
                    crew={data}
                    selected={selected}
                    setSelected={setSelected}
                    page={page}
                    reload={reload}
                    setHideList={setHideList}
                    hideList={hideList}
                    top={index === listData.length - 1 ? '-120%' : '80%'}
                  />
                ) : (
                  <></>
                )}
              </>
            );
          })
        : listData?.map((data, index) => (
            <div key={index}>
              {checkPage(page).isCrew && data.role && data.role.includes(collapsePanelName) ? (
                <DataRow
                  event={data}
                  venue={data}
                  guest={data}
                  account={data}
                  guestList={data}
                  crew={data}
                  selected={selected}
                  setSelected={setSelected}
                  page={page}
                  reload={reload}
                  setHideList={setHideList}
                  hideList={hideList}
                  top={index === listData.length - 1 ? '-120%' : '80%'}
                />
              ) : checkPage(page).isCrew && !data.role && collapsePanelName === 'No role' ? (
                <DataRow
                  event={data}
                  venue={data}
                  guest={data}
                  account={data}
                  guestList={data}
                  crew={data}
                  selected={selected}
                  setSelected={setSelected}
                  page={page}
                  reload={reload}
                  setHideList={setHideList}
                  hideList={hideList}
                  top={index === listData.length - 1 ? '-120%' : '80%'}
                />
              ) : !checkPage(page).isCrew ? (
                <DataRow
                  event={data}
                  venue={data}
                  guest={data}
                  account={data}
                  guestList={data}
                  crew={data}
                  selected={selected}
                  setSelected={setSelected}
                  page={page}
                  reload={reload}
                  setHideList={setHideList}
                  hideList={hideList}
                  top={index === listData.length - 1 || index === listData.length - 2 ? '-350%' : '80%'}
                />
              ) : (
                <></>
              )}
            </div>
          ))}
      {checkPage(page).isGuestList && Array.isArray(eventsWithoutGuestlist) && collapsePanelName === 'upcoming' ? (
        eventsWithoutGuestlist.map((data, index) => (
          <DataRow
            key={index}
            crew={data}
            selected={selected}
            setSelected={setSelected}
            page={page}
            reload={reload}
            setHideList={setHideList}
            hideList={hideList}
            eventWithoutGuestlist={data as any}
            top={index === listData.length - 1 || index === listData.length - 2 ? '-350%' : '80%'}
          />
        ))
      ) : (
        <></>
      )}
    </div>
  );
};

export default DataContainer;
