import Link from 'next/link';
import { FC } from 'react';

interface LinkWrapProps {
  children: React.ReactNode;
  hasLink: boolean;
  href: string;
  as: string;
}

const LinkWrap: FC<LinkWrapProps> = ({ children, href, as, hasLink }) => {
  return (
    <>
      {hasLink ? (
        <Link href={href} as={as} passHref>
          {children}
        </Link>
      ) : (
        children
      )}
    </>
  );
};

interface Part2Props {
  genres: string[] | undefined;
  href: string;
  as: string;
  smallScreen: boolean;
}

const Part2: FC<Part2Props> = ({ genres, href, as, smallScreen }) => {
  return (
    <LinkWrap hasLink={!smallScreen} as={as} href={href}>
      <div className="part2">{genres && genres.map(genre => <div key={genre}>{genre}</div>)}</div>
    </LinkWrap>
  );
};

export default Part2;
