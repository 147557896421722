import { Dispatch, FC, SetStateAction, useState } from 'react';
import { Event, GuestList, PrivateUser, Page } from '../../../../graphql/types';
import ThreeDot from '../ThreeDot';
import Part1 from './Part1';
import Part2 from './Part2';
import Part3 from './Part3';
import { numFormatter, checkPage, useSmallScreen } from '../../../utils';
import { mainPages } from '../../../customTypes';

interface DataRowProps {
  venue?: Page;
  event?: Event;
  eventWithoutGuestlist?: Event;
  guestList?: GuestList;
  account?: PrivateUser;
  guest?: any;
  crew?: any;
  selected: string | undefined;
  setSelected: Dispatch<SetStateAction<string | undefined>>;
  page: mainPages;
  reload: () => void;
  setHideList?: Dispatch<
    SetStateAction<{
      value: boolean;
      data: any;
    }>
  >;
  hideList?: {
    value: boolean;
    data: any;
  };
  top?: string;
}

const DataRow: FC<DataRowProps> = ({
  page,
  guest,
  selected,
  setSelected,
  venue,
  event,
  guestList,
  crew,
  account,
  reload,
  setHideList,
  hideList,
  top,
  eventWithoutGuestlist,
}) => {
  const genres = checkPage(page).isEvents
    ? event?.genres
    : checkPage(page).isVenues || checkPage(page).isFestivals || checkPage(page).isPromoters
    ? venue?.genres
    : [];
  const hasThreeDot = !checkPage(page).isFestivals && !checkPage(page).isPromoters && !checkPage(page).isCrew;
  const [openThreeDot, setOpenThreeDot] = useState<'open' | 'close'>('close');
  const smallScreen = useSmallScreen();

  const handleOpenThreeDot = () => {
    if (typeof window !== 'undefined') {
      setSelected(
        checkPage(page).isEvents
          ? event?.id
          : checkPage(page).isVenues || checkPage(page).isFestivals || checkPage(page).isPromoters
          ? venue?.id
          : checkPage(page).isAccounts
          ? account?.id
          : '',
      );
      setOpenThreeDot(
        window.screen.width <= 1200 &&
          selected ===
            (checkPage(page).isEvents
              ? event?.id
              : checkPage(page).isVenues || checkPage(page).isFestivals || checkPage(page).isPromoters
              ? venue?.id
              : checkPage(page).isAccounts
              ? account?.id
              : '') &&
          openThreeDot === 'open'
          ? 'close'
          : 'open',
      );
    }
  };

  const href = checkPage(page).isEvents
    ? '/events/[id]'
    : checkPage(page).isVenues || checkPage(page).isFestivals || checkPage(page).isPromoters
    ? '/pages/[id]'
    : checkPage(page).isAccounts
    ? '/accounts/[id]'
    : checkPage(page).isGuestList && !eventWithoutGuestlist
    ? '/guestlist/[id]'
    : eventWithoutGuestlist
    ? '/events/[id]'
    : '';

  const as =
    checkPage(page).isEvents && event
      ? `/events/${event.id}`
      : checkPage(page).isVenues || checkPage(page).isFestivals || checkPage(page).isPromoters
      ? `/pages/${venue?.id}`
      : checkPage(page).isAccounts
      ? `/accounts/${account?.id}`
      : checkPage(page).isGuestList && !eventWithoutGuestlist
      ? `/guestlist/${guestList?.id}`
      : eventWithoutGuestlist
      ? `/events/${eventWithoutGuestlist.id}`
      : '';

  const commonProps = {
    event,
    venue,
    account,
    page,
    guestList,
    numFormatter,
    crew,
    href,
    as,
  };

  return (
    <div
      className={`data-row${checkPage(page).isGuests ? ' guests' : ''}${checkPage(page).isGuests && smallScreen ? ' guests-small' : ''}${
        hasThreeDot ? '' : ' no-three-dot'
      } ${checkPage(page).isVenues ? ' pages' : ''}`}
      style={{
        opacity: eventWithoutGuestlist ? 0.5 : 1,
      }}
      onClick={() => {
        if (hideList && setHideList) {
          setHideList({
            value: true,
            data: crew,
          });
        }
      }}>
      <Part1 {...commonProps} guest={guest} eventWithoutGuestlist={eventWithoutGuestlist} />
      {!eventWithoutGuestlist ? (
        <>
          <Part2 genres={genres} href={href} as={as} smallScreen={smallScreen} />
          <Part3 {...commonProps} smallScreen={smallScreen} />
        </>
      ) : (
        <></>
      )}

      {hasThreeDot && !eventWithoutGuestlist ? (
        <ThreeDot
          open={openThreeDot}
          handleOpen={handleOpenThreeDot}
          eventId={event?.id}
          guest={guest}
          isAccounts={checkPage(page).isAccounts}
          isGuestList={checkPage(page).isGuestList}
          isPages={checkPage(page).isVenues}
          isEvents={checkPage(page).isEvents}
          accountId={account?.id}
          guestListId={guestList?.id}
          eventStatus={event?.status}
          reload={reload}
          top={top}
        />
      ) : (
        <></>
      )}
    </div>
  );
};

export default DataRow;
