import Link from 'next/link';
import Avatar from 'antd/lib/avatar/avatar';
import Image from 'next/image';
import { FC } from 'react';
import dayjs from 'dayjs';
import MailOutlined from '@ant-design/icons/MailOutlined';
import CheckCircleFilled from '@ant-design/icons/CheckCircleFilled';
import CheckCircleOutlined from '@ant-design/icons/CheckCircleOutlined';
import CloseCircleOutlined from '@ant-design/icons/CloseCircleOutlined';
import LoadingOutlined from '@ant-design/icons/LoadingOutlined';
import QuestionCircleOutlined from '@ant-design/icons/QuestionCircleOutlined';

import { Event, GuestList, GuestStatus, PrivateUser, Page, PageStatus } from '../../../../graphql/types';
import { mainPages } from '../../../customTypes';
import { checkPage } from '../../../utils';
import { Button } from 'antd';

interface Part1Props {
  event: Event | undefined;
  venue: Page | undefined;
  eventWithoutGuestlist: Event | undefined;
  guest: any | undefined;
  account: PrivateUser | undefined;
  guestList: GuestList | undefined;
  page: mainPages;
  numFormatter: (num: any) => any;
  crew: any;
  href: string;
  as: string;
}

const Part1: FC<Part1Props> = ({ page, event, guestList, venue, guest, crew, account, numFormatter, href, as, eventWithoutGuestlist }) => {
  const dates = checkPage(page).isGuests && guest?.dates ? `( > ${guest.dates} )` : guest?.maximumDates ? `( max. ${guest.maximumDates} )` : '';
  const guestFirstName = guest && guest.firstName ? guest.firstName : '';
  const guestLastName = guest && guest.lastName ? guest.lastName : '';
  const guestName = guestLastName === '' ? `${guest?.email}` : `${guestFirstName} ${guestLastName}`;

  const avatarUrl = checkPage(page).isEvents
    ? event?.coverImageUrl
    : checkPage(page).isVenues || checkPage(page).isFestivals || checkPage(page).isPromoters
    ? venue?.logoUrl
    : checkPage(page).isAccounts
    ? account?.profilePicURL
    : checkPage(page).isGuestList
    ? guestList?.event?.coverImageUrl || eventWithoutGuestlist?.coverImageUrl || '/images/app-icon.png'
    : checkPage(page).isCrew
    ? crew.user.profilePicURL
    : '';

  const name = checkPage(page).isEvents
    ? event?.name
    : checkPage(page).isGuests
    ? guestName
    : checkPage(page).isVenues || checkPage(page).isFestivals || checkPage(page).isPromoters
    ? venue?.name
    : checkPage(page).isAccounts
    ? account?.displayName
    : checkPage(page).isGuestList && !guestList?.title
    ? guestList?.event?.name || eventWithoutGuestlist?.name
    : checkPage(page).isGuestList && guestList?.title
    ? guestList?.title
    : checkPage(page).isCrew
    ? crew.user.displayName
    : '';

  const handleGuestStatus = (status: GuestStatus | undefined) => {
    let icon;
    let nextStatus;
    if (status === GuestStatus.Attending) {
      icon = <CheckCircleFilled style={{ color: '#03DAC5' }} />;
      nextStatus = GuestStatus.UserDeclined;
    } else if (status === GuestStatus.Approved) {
      icon = <CheckCircleOutlined />;
      nextStatus = GuestStatus.Attending;
    } else if (status === GuestStatus.Requested) {
      icon = <LoadingOutlined />;
      nextStatus = GuestStatus.Approved;
    } else if (status === GuestStatus.Invited) {
      icon = <MailOutlined />;
      nextStatus = GuestStatus.Approved;
    } else if (status === GuestStatus.UserDeclined) {
      icon = <CloseCircleOutlined style={{ color: '#FF427E' }} />;
      nextStatus = status;
    } else {
      icon = <QuestionCircleOutlined />;
      nextStatus = '';
    }

    return { icon, nextStatus };
  };

  const startDate = checkPage(page).isEvents
    ? event?.startDate
    : checkPage(page).isGuestList && guestList && guestList.startTime
    ? guestList.startTime
    : guestList?.event?.startDate || eventWithoutGuestlist?.startDate;

  const crewRoles = checkPage(page).isCrew && Array.isArray(crew.role) ? crew.role : '';

  return (
    <Link href={href} as={as} passHref>
      <div className={`part1 ${checkPage(page).isGuestList ? 'guestList' : ''}`}>
        <Avatar shape="square" size={48} style={{ borderRadius: checkPage(page).isCrew ? 99 : 8, marginRight: 22 }} src={avatarUrl}>
          {!avatarUrl && checkPage(page).isGuests && guest && guest.firstName
            ? guest?.firstName?.charAt(0)
            : checkPage(page).isAccounts && !avatarUrl
            ? account?.displayName?.charAt(0)
            : '?'}
        </Avatar>

        <div>
          <h1>
            {name} {dates}
          </h1>
          {checkPage(page).isEvents || checkPage(page).isGuestList ? (
            <>
              {event?.venue?.status === PageStatus.Private ? (
                <div
                  style={{
                    zIndex: 100_000_000_000,
                  }}
                  onClick={e => {
                    e.preventDefault();
                    window.location.href = 'mailto:support@partymate.app?subject=Page verification';
                  }}>
                  <p
                    style={{
                      margin: 0,
                    }}>
                    <Button
                      type="link"
                      danger
                      style={{
                        zIndex: 100_000_000_000,
                        padding: 0,
                      }}>
                      Private page - Click here to verify
                    </Button>
                  </p>
                </div>
              ) : (
                <></>
              )}
              <h2>{dayjs(startDate).format('DD.MM.YYYY HH:mm A')}</h2>
            </>
          ) : (checkPage(page).isGuests && guest) || checkPage(page).isAccounts ? (
            <div className="guests-status">
              <h2>{checkPage(page).isAccounts ? account?.email : guest?.status}</h2>
              {checkPage(page).isGuests && guest && <div className="change-status">{handleGuestStatus(guest.status).icon}</div>}
            </div>
          ) : checkPage(page).isCrew ? (
            <div className="guests-status">
              {crewRoles &&
                crewRoles.map((e, i) => (
                  <div key={i} className="crew-pill">
                    <h2
                      style={{
                        fontSize: '13px',
                        lineHeight: '18px',
                        fontWeight: '500',
                        letterSpacing: '-0.08px',
                        color: '#9697A1',
                      }}>
                      {e}
                    </h2>
                  </div>
                ))}
            </div>
          ) : (
            venue && (
              <div className="data">
                {venue.sourceStarRating &&
                  [1, 2, 3, 4, 5].map(i => (
                    <Image
                      src={`/icons/star_${venue.sourceStarRating && i <= venue.sourceStarRating ? 'filled' : 'empty'}.svg`}
                      layout="fixed"
                      width={18}
                      height={18}
                      key={i}
                      alt="PM_rating"
                    />
                  ))}
                {venue.status === PageStatus.Private ? (
                  <div
                    style={{
                      zIndex: 100_000_000_000,
                    }}
                    onClick={e => {
                      e.preventDefault();
                      window.location.href = 'mailto:support@partymate.app?subject=Page verification';
                    }}>
                    <p
                      style={{
                        margin: 0,
                      }}>
                      <Button
                        type="link"
                        danger
                        style={{
                          zIndex: 100_000_000_000,
                          padding: 0,
                        }}>
                        Private - Click here to verify
                      </Button>
                    </p>
                  </div>
                ) : (
                  <></>
                )}
                {venue.priceLevel && venue.sourceFanCount && (
                  <h2>
                    {' '}
                    {venue.sourceStarRating && venue.priceLevel && venue.sourceFanCount && ' · '}
                    {'€'.repeat(venue.priceLevel)} · {numFormatter(venue.sourceFanCount)} Fans
                  </h2>
                )}
              </div>
            )
          )}
        </div>
      </div>
    </Link>
  );
};

export default Part1;
