import { FC } from 'react';
import { useTranslation } from 'react-i18next';
import Input from 'antd/lib/input';
import Image from 'next/image';

import { GetEventsCountQueryHookResult, GetGuestListsQueryHookResult, GetVenuesCountQueryHookResult } from '../../../../graphql/types';
import Sorter from './Sorter';
import { numFormatter, checkPage } from '../../../utils';
import { mainPages } from '../../../customTypes';

interface SearchBarProps {
  page: mainPages;
  handleSearch: (input: string) => Promise<void>;
  handleFilter?: (input: string) => Promise<void>;
  eventsCounter?: GetEventsCountQueryHookResult;
  venuesCounter?: GetVenuesCountQueryHookResult;
  guestLists?: GetGuestListsQueryHookResult;
  guestsCounter?: number;
  crew?: any;
  isLoading?: boolean;
}

const SearchBar: FC<SearchBarProps> = ({ page, handleSearch, handleFilter, eventsCounter, venuesCounter, guestsCounter, guestLists, crew, isLoading }) => {
  const { t } = useTranslation('page');

  const leftText = t(
    `${
      checkPage(page).isEvents
        ? 'events'
        : checkPage(page).isGuests
        ? 'guests'
        : checkPage(page).isAccounts
        ? 'accounts'
        : checkPage(page).isFestivals
        ? 'festivals'
        : checkPage(page).isPromoters
        ? 'promoters'
        : checkPage(page).isCrew
        ? 'crew'
        : 'venues'
    }.browse`,
  );

  const crewPlan = checkPage(page).isCrew && crew.license ? crew.license.plan : 'No plan active';
  const crewCurrentUsers = checkPage(page).isCrew && Array.isArray(crew.crew) ? crew.crew.length : 0;
  const crewTotalUsers = checkPage(page).isCrew && crew.license ? crew.license.users : crewCurrentUsers;
  const crewUsage = ((crewCurrentUsers / crewTotalUsers) * 100).toFixed();

  const placeholderNumber = numFormatter(
    checkPage(page).isEvents
      ? eventsCounter?.data?.countEvents
      : checkPage(page).isGuests
      ? guestsCounter
      : checkPage(page).isGuestList && guestLists && guestLists.data
      ? guestLists.data.guestLists.length
      : checkPage(page).isCrew
      ? crewCurrentUsers
      : venuesCounter?.data?.countVenues,
  );
  const placeholder = checkPage(page).isGuestList
    ? `${t('guestlist.browse1')} ${placeholderNumber ? placeholderNumber : 0} ${t('guestlist.browse2')}`
    : `${placeholderNumber ? placeholderNumber : 0} ${leftText}`;

  return (
    <>
      {!checkPage(page).isAccounts && (
        <>
          {!checkPage(page).isCrew ? (
            <div className="search-bar">
              <div className="cell" style={{ width: '100%' }}>
                <Image src={'/icons/search.svg'} layout="fixed" width={16} height={16} alt="PM_search" />
                <Input
                  placeholder={placeholder}
                  onChange={e => {
                    e.preventDefault();
                    handleSearch(e.target.value);
                  }}
                />
              </div>
              <Sorter handleFilter={handleFilter} show={checkPage(page).isGuests && placeholderNumber > 0} />
            </div>
          ) : (
            <></>
          )}

          {checkPage(page).isCrew && !isLoading && (
            <div className="crew-main-card">
              <div
                className="crew-card-row"
                style={{
                  marginBottom: '24px',
                }}>
                <div>
                  <h1
                    style={{
                      fontSize: '12px',
                      lineHeight: '14px',
                      fontWeight: '700',
                      letterSpacing: '0.6px',
                    }}
                    className="crew-card-small-text">
                    {t('crew.yourPlan')}
                  </h1>
                  <h1
                    style={{
                      fontSize: '28px',
                      lineHeight: '38.25px',
                      fontWeight: '700',
                      letterSpacing: '0.3px',
                    }}
                    className="crew-card-big-text">
                    {crewPlan}
                  </h1>
                </div>
                <div className="crew-card-usage crew-card-row mb">
                  <div style={{ textAlign: 'right', marginRight: 8 }}>
                    <h1
                      style={{
                        fontWeight: '700',
                        fontSize: '12px',
                        lineHeight: '14px',
                        letterSpacing: '0.6px',
                      }}
                      className="crew-card-small-text">
                      {t('crew.crewSlots')}
                    </h1>
                    <h1
                      style={{
                        fontWeight: '400',
                        fontSize: '12px',
                        lineHeight: '14px',
                        letterSpacing: '0.6px',
                      }}
                      className="crew-card-small-text light">
                      {t('crew.used')}
                    </h1>
                  </div>
                  <h1
                    className="crew-card-big-text large"
                    style={{
                      fontWeight: '700',
                      fontSize: '34px',
                      lineHeight: '41px',
                      letterSpacing: '0.3px',
                    }}>
                    {crewCurrentUsers}
                    <span
                      style={{
                        fontWeight: '400',
                        fontSize: '34px',
                        lineHeight: '41px',
                        letterSpacing: '0.3px',
                      }}>
                      /{crewTotalUsers}
                    </span>
                  </h1>
                </div>
              </div>
              <div className="crew-card-row" style={{ justifyContent: 'flex-start' }}>
                <a className="crew-card-upgrade crew-card-row" href="mailto:support@partymate.app">
                  <Image src={'/icons/up_arrow.svg'} alt="upgrade crew" width={16} height={16} layout="fixed" />
                  <h1 className="crew-card-small-text ml">{t('crew.upgrade')}</h1>
                </a>
                <div className="crew-card-progress-bar">
                  <div className="crew-card-progress-bar-inner" style={{ width: `${crewUsage}%` }} />
                </div>
              </div>
            </div>
          )}
        </>
      )}
    </>
  );
};

export default SearchBar;
