import message from 'antd/lib/message';
import Modal from 'antd/lib/modal/Modal';
import { Dispatch, FC, SetStateAction, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDeactivateUserPageRoleMutation, useRemoveCrewMemberMutation } from '../../../graphql/types';

interface RemoveCrewMemberProps {
  show: boolean;
  setShow: Dispatch<SetStateAction<boolean>>;
  type: 'remove' | 'deactivate';
  setHideList?: Dispatch<
    SetStateAction<{
      value: boolean;
      data: any;
    }>
  >;
  reload: () => void;
  crewId?: any;
}

const RemoveCrewMember: FC<RemoveCrewMemberProps> = ({ show, setShow, type, setHideList, reload, crewId }) => {
  const { t } = useTranslation('page');
  const [disabled, setDisabled] = useState<boolean>(false);
  const [deactivateUserPageRole] = useDeactivateUserPageRoleMutation();
  const [removeCrewMember] = useRemoveCrewMemberMutation();

  const handleSubmit = async () => {
    setDisabled(true);

    if (type === 'deactivate')
      await deactivateUserPageRole({
        variables: {
          crewId,
        },
      })
        .then(e => {
          if (e.data?.deactivateUserPageRole.success) {
            message.success(`The crew member was ${type}d!`);
            reload();
            setShow(false);
            if (setHideList) setHideList({ value: false, data: {} });
          }
        })
        .catch(() => message.error('Something went wrong, please try again.'));
    else if (type === 'remove')
      await removeCrewMember({
        variables: {
          crewId,
        },
      })
        .then(e => {
          if (e.data?.removeCrewMember.success) {
            message.success(`The crew member was ${type}d!`);
            reload();
            setShow(false);
            if (setHideList) setHideList({ value: false, data: {} });
          }
        })
        .catch(() => message.error('Something went wrong, please try again.'));

    setTimeout(() => {
      setDisabled(false);
    }, 3000);
  };
  return (
    <Modal
      visible={show}
      onCancel={() => setShow(!show)}
      footer={
        <div className="modal-footer">
          <div className="button-left" onClick={() => setShow(false)}>
            {t('events.cancel')}
          </div>
          <div className={`button-right ${disabled ? 'disabled' : ''} red`} onClick={() => handleSubmit()}>
            {t(`crew.${type}`)}
          </div>
        </div>
      }
      className="modal-container">
      <h1 className="title">{t(`crew.${type === 'remove' ? 'removeCrew' : 'deactivateCrew'}`)}</h1>
    </Modal>
  );
};

export default RemoveCrewMember;
